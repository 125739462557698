export const focusById = ( eleId ) => {
    if(!eleId?.length) return;
    const curId = eleId?.replace('#', '');
    document.getElementById(curId)?.scrollIntoView({behavior: 'smooth'})
}

export const openNewTb = ( link ) => {
    window.open(
        link,
        '_blank'
    );
}