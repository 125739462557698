export const NavBarLinks = [
    {
        title: 'About',
        link: '#About'
    },
    {
        title: 'Skills',
        link: '#Skills'
    },
    {
        title: 'Projects',
        link: '#Projects'
    },
    {
        title: 'Experience',
        link: '#Experience'
    },
]

export const AllProjects = [
    {
        title: "ChatHere",
        description: `An online chatting platform so efficient and feature-packed, it might just make your Wi-Fi feel like part of the gang — email chats, group rooms, and local network gossip included!`,
        website: 'https://chathere.gvishnu.in',
        github: undefined,
        imgSrc: 'https://gvishnu.in/chathere-pic.jpg',
    },
    {
        title: "Unified Login Sytem",
        description: `A secure and versatile login system that allows users to log in once and seamlessly access multiple integrated apps without re-authentication.`,
        website: 'https://unified-login-system.gvishnu.in',
        github: undefined,
        imgSrc: 'https://gvishnu.in/unified-login-system-pic.jpg',
    }
]

export const NavBarHeight = '67.5px';

export const linkedinLink = 'https://linkedin.com/in/g-vishnu/';

export const AllSkills = [
    {
        title: 'HTML',
        pathName: 'html',
    },
    {
        title: 'CSS',
        pathName: 'css',
    },
    {
        title: 'Javascript',
        pathName: 'javascript',
    },
    {
        title: 'C',
        pathName: 'c',
    },
    {
        title: 'C++',
        pathName: 'cpp',
    }, 
    {
        title: 'DSA',
        pathName: 'dsa',
    },
    {
        title: 'React Js',
        pathName: 'react',
    }, 
    {
        title: 'Redux',
        pathName: 'redux',
    },
    {
        title: 'Express Js',
        pathName: 'express',
    }, 
    {
        title: 'Node Js',
        pathName: 'node',
    }, 
    {
        title: 'npm',
        pathName: 'npm',
    },
    {
        title: 'MySQL',
        pathName: 'mysql',
    },
    {
        title: 'Sass',
        pathName: 'sass',
    },
    {
        title: 'Tailwind CSS',
        pathName: 'tailwind',
    },
    {
        title: 'Bootstrap',
        pathName: 'bootstrap',
    },
    {
        title: 'Git',
        pathName: 'git',
    },
    {
        title: 'GitHub',
        pathName: 'github',
    },
    {
        title: 'Ant Design',
        pathName: 'antdesign',
    },
    {
        title: 'Swagger',
        pathName: 'swagger',
    },
    {
        title: 'Postman',
        pathName: 'postman',
    },
]

export const myDetails = {
    "name": "Vishnu G",
    "shortDescription": "Software Developer with expertise in React.js and Full-Stack Development",
    "longDescription": `I'm a passionate React.js developer with over a year of experience creating web applications that are as functional as they are visually stunning. Whether it’s crafting sleek UIs with animations that leave users in awe or implementing secure systems like my very own Unified Login System, I thrive on turning ideas into reality.
\n
When I’m not busy coding or perfecting projects like ChatHere—an online chatting platform with features so unique it might just make your Wi-Fi jealous—you’ll find me exploring the ever-evolving tech world, dreaming up new ways to make web development cooler.

Oh, and did I mention I lead a team of developers? That means I not only write code but also wield the magical skill of making meetings a little less boring. Life motto? "If it moves, animate it; if it doesn’t, debug it until it does!"`,
    "profilePicLink": "",
    "projects": [
        {
            "title": "ChatHere",
            "description": "An online chatting platform with features such as email-to-email chat, group chats using room names and passwords, and local network chat for users on the same Wi-Fi network. Built with React.js, Express.js, WebSocket, and Socket.IO."
        },
        {
            "title": "Unified Login System",
            "description": "A secure and simple login system allowing users to log in once and access multiple integrated apps without re-authentication. It emphasizes security through bcrypt password hashing, JWT tokens, OTP, CAPTCHA, and secured cookies, along with providing features like feedback gathering, notifications, and project management. Built with React.js, Express.js, Node.js, and MySQL."
        }
    ],
    "skills": [
        "React.js",
        "Next.js",
        "Node.js",
        "Express.js",
        "MySQL",
        "WebSocket",
        "Socket.IO",
        "JWT",
        "bcrypt",
        "CAPTCHA implementation"
    ],
    "experience": [
        {
            "role": "React.js Developer",
            "duration": "1 year",
            "description": "Focused on developing and optimizing web applications using React.js.",
            "points": [
                "Developed and optimized web applications using React.js, ensuring responsive design and seamless user experience.",
                "Collaborated with back-end developers to integrate APIs and ensure efficient data flow.",
                "Debugged and resolved performance issues to enhance application efficiency.",
                "Implemented reusable components to streamline development and ensure consistency across projects."
            ]
        },
        {
            "role": "Team Lead",
            "teamSize": 4,
            "description": "Led a team of developers to deliver software projects efficiently and on time.",
            "points": [
                "Led a team of 4 developers to design, develop, and deploy software projects on time.",
                "Provided technical guidance and mentorship to team members to improve overall performance.",
                "Facilitated team meetings to plan sprints and track project progress using Agile methodologies.",
                "Reviewed code to ensure adherence to best practices and maintain high-quality standards."
            ]
        }
    ]
}
