import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom';
import './global.css';
import Navbar from './components/NavBar';
import Home from './pages/Home';

function App() {

  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes >
          <Route path='/' element={ <Home /> } />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
