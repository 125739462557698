import React from "react";

const Experience = () => {
    return (
        <>
            <div id="Experience" className="w-[80vw] mx-[10vw] my-[100px] experience-section scroll-mt-[70px]">
                <p className="text-4xl font-semibold">Experience</p>
                <p className="text-2xl font-semibold text-gray-500 mt-5">From leading a team of developers to building innovative projects, my journey has been all about turning challenges into opportunities. Each step has honed my technical skills, enhanced my problem-solving abilities, and fueled my passion for creating impactful solutions. Here's a glimpse into the milestones that shaped my career!</p>

            </div>
        </>
    )
}

export default Experience;