import React from "react";
import { Icons } from "./CustomIcons";
import { AllSkills } from "../utils/constant";
import { useRef } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';

const Skills = () => {

    const container = useRef();

    useGSAP(
        () => {
            const boxes = gsap.utils.toArray('.SkillEle');
            boxes.forEach((box, index) => {
                gsap.from(box, {
                    x: index % 2 === 0 ? '-100vw' : '100vw', // Adjusted x for subtler movement
                    rotation: index % 2 === 0 ? '-45' : '45', // Reduced rotation for smoothness
                    ease: 'power3.out', // Smoother easing function
                    scrollTrigger: {
                      trigger: box,
                      start: 'bottom bottom', // Start when the box is just visible
                      end: 'top 50%', // End halfway through the viewport
                      scrub: 0.5, // Smooth animation tied to scroll
                      // markers: true, // Uncomment for debugging
                    },
                });
            });
        },
        { scope: container }
    );

    return (
        <>
            <div id="Skills" className="w-[80vw] mx-[10vw] my-[100px] project-section scroll-mt-[70px]">
                <p className="text-4xl font-semibold">Skills</p>
                <p className="text-2xl font-semibold text-gray-500 mt-5">My skills are like a full-stack toolkit — ranging from crafting dynamic UIs with React.js to building robust backend systems with Express.js and Node.js. Add in DSA wizardry, C++ precision, JavaScript mastery, and a knack for logical thinking, and you've got a developer ready to tackle any challenge with style!</p>
                <div ref={container} className="flex gap-5 my-5 justify-around flex-wrap">
                    {AllSkills.map(skillEle => <SkillEle data={skillEle} />)}
                </div>
            </div>
        </>
    )
}

export default Skills;

const SkillEle = ({ data }) => {
    return (
        <div
            className={`SkillEle flex gap-2 py-2 px-5 bg-white items-center text-gray-600 border-gray-600 border-2 rounded-3xl shadow-lg transition delay-50 duration-300 ease-in-out hover:-translate-y-1 hover:scale-105`}
        >
            {Icons[data.pathName] && <div className="w-[25px] stoke-2">
                {Icons[data.pathName]}
            </div>}
            <p className="text-2xl font-semibold">{data.title}</p>
        </div>
    )
}