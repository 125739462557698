import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { NavBarLinks } from "../utils/constant";
import { focusById } from "../utils/helper";

const Navbar = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const location = useLocation()

    useEffect(() => {
        if(location.hash?.length > 1) focusById(location.hash);
    }, [location])

    const handleOpen = () => {
        setOpenMenu(!openMenu);
    }

    const NavListEle = () => {
        return (
            <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                {NavBarLinks.map(ele => {
                    const isCurrentPage = ele.link == location.hash;

                    return (
                        <li>
                            {isCurrentPage ? <NavLink to={ele.link} onClick={openMenu && handleOpen} className="block text-xl py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-500 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page">{ele.title}</NavLink>
                            :
                            <NavLink to={ele.link} onClick={openMenu && handleOpen} className="block text-xl py-2 px-3 text-gray-500 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-400 md:p-0 dark:text-white md:dark:hover:text-blue-100 dark:hover:bg-gray-500 dark:hover:text-white md:dark:hover:bg-transparent">{ele.title}</NavLink>}
                        </li>
                    )
                })}
            </ul>
        )
    }

    return (
        <div className="w-[80vw] mx-[10vw] fixed py-5 " style={{zIndex: 9}}>
            <nav class="bg-white border-gray-200 dark:bg-gray-900 rounded-[10px] shadow-xl">
                <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 px-7">
                    <NavLink to={"/"} class="flex items-center space-x-3 rtl:space-x-reverse">
                        <span class="text-gray-500 self-center text-2xl font-semibold whitespace-nowrap dark:text-white">VISHNU G</span>
                    </NavLink>
                    <button onClick={handleOpen} data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
                        <span class="sr-only">Open main menu</span>
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                    <div class={`${openMenu ? '' : 'hidden'} w-full md:block md:w-auto`} id="navbar-default">
                        <NavListEle />
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar;